import onDomReady from '../utils/onDomReady';
import PnModal from '../modal/modal';
import httpPOST from '../utils/httpPost';

const callersClass = 'jsOpenLoginModal';

class LoginFormModal {
  constructor() {
    this.modal = null;
    this.form = null;
    this.callback = null;
  }

  open() {
    // stop banners popups
    window.PopupIsOpened = true;
    if (!this.modal) {
      this.modal = new PnModal(500);
      this.modal.fg.addEventListener('submit', (e) => this.onFormSubmit(e));
      this.modal.fg.classList.add('LoginModalWindow');
      // custom close
      this.modal.closebtn.style.display = 'none';
      this.modal.fg.addEventListener('click', (e) => this.onClickCloseDetect(e));
    }

    this.modal.openElement('#jsLoginModalContainer');
  }

  setCallback(callback) {
    this.callback = callback;
  }

  onFormSubmit(e) {
    e.preventDefault();

    const form = e.target;
    const input = {
      nick: form.elements.nick.value.trim(),
      password: form.elements.password.value.trim(),
      event: form.elements.event.value.trim(),
    };
    if (!input.nick) {
      return;
    }

    this.form = form;
    this.error('');
    form.classList.add('loading');

    httpPOST(
      window.document.location.href,
      input,
      (content) => this.onHttpResponseReceived(content),
    );
  }

  onClickCloseDetect(e) {
    const closeBtnClicked = e.target.classList.contains('modalWindowCloseButton');
    if (closeBtnClicked) {
      e.preventDefault();
      this.modal.close();
    }
  }

  error(msg) {
    const divError = this.form.getElementsByClassName('pn-error')[0];
    const divNick = this.form.elements.nick.parentNode;
    const divPassword = this.form.elements.password.parentNode;
    const divTip = divPassword.getElementsByClassName('tip')[0];
    divError.innerText = msg;
    divTip.innerText = msg;
    if (msg === '') {
      divError.style.display = 'none';
      divNick.classList.remove('needAttention');
      divPassword.classList.remove('needAttention');
    } else {
      divError.style.display = '';
      divNick.classList.add('needAttention');
      divPassword.classList.add('needAttention');
    }
    this.modal.resizeTo();
  }

  onHttpResponseReceived(content) {
    const data = JSON.parse(content);
    if (data.error) {
      this.error(data.error);
      this.form.classList.remove('loading');
    } else if (typeof this.callback === 'function') {
      this.callback(this, data);
    } else if (this.callback && window[this.callback]) {
      window[this.callback](this, data);
    } else if (data.goto && data.goto !== document.location.href) {
      document.location.replace(data.goto);
    } else {
      document.location.reload(true);
    }
  }
}

function onCallerClick(e, callback) {
  e.preventDefault();
  window.modalForm.setCallback(callback || this.getAttribute('data-onlogin'));
  window.modalForm.open();
}

function LoginFormInit() {
  if (!window.loginFormInitExecuted) {
    window.loginFormInitExecuted = true;
    window.modalForm = new LoginFormModal();

    const callers = document.getElementsByClassName(callersClass);
    for (let i = 0; i < callers.length; i += 1) {
      callers[i].addEventListener('click', onCallerClick);
    }
  }
}

onDomReady(LoginFormInit);

document.addEventListener('turbo:load', () => {
  window.loginFormInitExecuted = false;
  LoginFormInit();
});

export default onCallerClick;
