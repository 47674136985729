export default function httpPOST(url, data, fnSuccess, fnError) {
  let http = false;
  try {
    http = new XMLHttpRequest();
  } catch {
    http = false;
  }
  if (!http) {
    return;
  }
  if (http.readyState !== 0) {
    this.http.onreadystatechange = () => { };
    this.http.abort();
  }
  http.onreadystatechange = () => {
    if (http.readyState !== 4) {
      return;
    }
    if (http.status === 200 || http.status === 0) {
      if (fnSuccess) {
        fnSuccess(http.responseText);
      }
    } else if (fnError) {
      fnError(http);
    }
  };
  // serialize
  const paramChunks = [];
  let paramString = '';
  Object.keys(data).forEach((key) => {
    paramChunks.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
  });
  paramString = paramChunks.join('&');
  http.open('POST', url, true);
  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  http.send(paramString);
}
